import axios from 'axios';
// var serverURL = "https://softechplanettechnology.com/softwares/wedsgood/api"
var serverURL = "https://justevent.in/backend/api"

const postData = async (url, body) => {
    try {
        let headers = {}
        if (localStorage.getItem("token")) {
            headers = {
                Authorization: localStorage.getItem("token")
            }
        }
        var response = await axios.post(`${serverURL}/${url}`, body, { headers })
        var result = response.data
        return (result)
    }
    catch (e) {
        return { status: false }
    }
}



const getData = async (url) => {
    try {
        let headers = {}
        if (localStorage.getItem("token")) {
            headers = {
                Authorization: localStorage.getItem("token")
            }
        }
        var response = await axios.get(`${serverURL}/${url}`, { headers })
        var result = response.data
        return (result)
    }
    catch (e) {
        return { status: false}
    }
}
export { serverURL, postData, getData }



// import axios from 'axios';
// var serverURL="http://localhost:5000"
// const postData=async(url,body)=>{
//     try
//     {
//         var response=await axios.post(`${serverURL}/${url}`,body)
//         var result=response.data
//         return(result)
//     }
//     catch(e)
//     {
//         return(null)
//     }
// }



// const getData=async(url)=>{
//     try
//     {
//         var response=await axios.get(`${serverURL}/${url}`)
//         var result=response.data
//         return(result)
//     }
//     catch(e)
//     {
//         return(null)
//     }
// }
// export{serverURL,postData,getData}